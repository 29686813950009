import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./index.css";
import "./App.css";
import RoutesLink from "./routes";
import reportWebVitals from "./reportWebVitals";
// import DeviceDetection from "./Screen/Device/DeviceDetection";
import i18n from "./i18n.js";
import { I18nextProvider } from "react-i18next";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      {/* <DeviceDetection> */}
      <RoutesLink />
      {/* </DeviceDetection> */}
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import BottomNav from "../BottomNav";
import { IoIosAdd, IoIosClose } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const Button = [
    {
      name: "Add document",
      class: "border-b-2",
      onClick: () => {
        setIsOpen(false);
        navigate("/upload-document/");
      },
    },
    {
      name: "Add cost",
      class: "border-b-2",
      onClick: () => {
        setIsOpen(false);
        navigate("/add-costs/");
      },
    },
    {
      name: "Report a claim",
      onClick: () => {
        setIsOpen(false);
        navigate("/report-claim/");
      },
    },
  ];

  // Determine if the current URL is '/upload-document/'
  const hideNav =
    location.pathname === "/upload-document/" ||
    location.pathname === "/add-costs/" ||
    location.pathname === "/single-campaign/" ||
    location.pathname === "/report-claim/";

  return (
    <div className="flex flex-col h-screen bg-SideDark relative items-center max-w-[450px]  mx-auto ">
      <div className="flex-1 overflow-auto pb-20 w-full ">{children}</div>

      {!hideNav && (
        <button
          className="fixed  bottom-[4.5rem] text-2xl right-2 z-20 p-1 bg-black text-white rounded-full shadow-lg focus:outline-none"
          onClick={toggleModal}
        >
          <IoIosAdd size={60} />
        </button>
      )}

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 max-w-[450px] mx-auto">
          <div className="w-full bg-white rounded-t-lg  mx-auto">
            <div className="flex justify-end relative -top-4 px-1">
              <button
                onClick={toggleModal}
                className="text-white bg-black w-fit p-2 rounded-full focus:outline-none"
              >
                <IoIosClose size={30} />
              </button>
            </div>
            <div className="space-y-1 relative -top-4">
              {Button.map((button, index) => (
                <div key={index} className={`${button?.class ?? ""}`}>
                  <button
                    onClick={button.onClick}
                    className="px-3 w-full py-4 text-left"
                  >
                    {button.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Navigation Bar */}
      {!hideNav && (
        <div className="fixed inset-x-0 bottom-0 z-10 w-full o">
          <BottomNav />
        </div>
      )}
    </div>
  );
};

export default MainLayout;

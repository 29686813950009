import React from "react";
import Theme from "../../../resources/Theme/Theme";
import { NavLink } from "react-router-dom";
function BottomNav() {
  const NaviGationData = [
    {
      title: "Trip",
      icon: Theme.Icon.Trip,
      link: "/",
      activeClass: "",
    },
    {
      title: "Stats",
      icon: Theme.Icon.Stats,
      link: "/stats",
    },
    {
      title: "Campaign",
      icon: Theme.Icon.Campaign,
      link: "/campaigns/",
    },
    {
      title: "Notification",
      icon: Theme.Icon.Notification,
      link: "/notifications/",
    },
    {
      title: "Profile",
      icon: Theme.Icon.Profile,
      link: "/profile",
    },
  ];

  const commonClass =
    "flex flex-col items-center justify-center text-gray-500 dark:text-gray-400";
  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-[65px] bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full py-2 max-w-lg grid-cols-5 mx-auto">
        {NaviGationData.map((item, index) => (
          <NavLink
            key={index}
            to={item.link}
            className={({ isActive }) =>
              isActive
                ? `active_class text-black font-bold ${commonClass}`
                : `text-gray-400 ${commonClass}`
            }
          >
            <img src={item.icon} alt={item.title} className="w-7 h-7 mb-1" />
            <span className="text-sm">{item.title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default BottomNav;

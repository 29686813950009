const baseUrl = process.env.PUBLIC_URL;

const SLUGS = {
  // Public Path
  _homePath: baseUrl + "/",
  _loginPath: baseUrl + "/login/",
  _forgetPasswordPath: baseUrl + "/forget-password/",
  _registerPath: baseUrl + "/register/",
  _single_tripPath: baseUrl + "/single-trip/",
  _upload_documentPath: baseUrl + "/upload-document/",
  _add_costsPath: baseUrl + "/add-costs/",
  _add_report_claimPath: baseUrl + "/report-claim/",
  _statsPath: baseUrl + "/stats/",
  _stats_single_dayPath: baseUrl + "/stats-single-day/",
  _campaignsPath: baseUrl + "/campaigns/",
  _single_campaignPath: baseUrl + "/single-campaign/",
  _notificationsPath: baseUrl + "/notifications/",
  _profilePath: baseUrl + "/profile/",
  _car_details: baseUrl + "/car-details/",
  _fleet_details: baseUrl + "/fleet-details/",
  _contact_support: baseUrl + "/contact-support/",
  _help: baseUrl + "/help/",
  _rate_us: baseUrl + "/rate-us/",
  _terms_conditions: baseUrl + "/terms-conditions/",
  _privacy_policy: baseUrl + "/privacy-policy/",
  _my_costs: baseUrl + "/my-costs/",
  _my_documents: baseUrl + "/my-documents/",
  _my_claims: baseUrl + "/my-claims/",
  _my_fuel_cards: baseUrl + "/my-fuel-cards/",
};

export default SLUGS;

import Cookies from "universal-cookie";

const cookie = new Cookies();

// Get the token value of the cookie with the specified name
const authToken = cookie.get("@df_access_token");
// Check if env is production or development
// const api =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_DEVELOPMENT_V
//     : process.env.REACT_APP_PRODUCTION_V;

const api = "https://digitalfleet.eu/api/1/";
const Config = {
  // List of all the API's
  API_URL: {
    _loginAPI: `${api}oauth/token/`,
    _reset_password: `${api}users/password-reset/`,
    _registration: `${api}users/registration/`,
    _logout: `${api}oauth/revoke_token/`,
    _forgetPassword: `${api}users/sendForgottenPasswordEmail/`,
    _me: `${api}users/me/`,
    _avatar: `${api}users/me/avatar/`,
    _dailyTrip: `${api}stats/day/`,
    _weeklyTrip: `${api}stats/week/`,
    _monthlyTrip: `${api}stats/month/`,
    _dailyFleet: `${api}stats/fleet/day/`,
    _weeklyFleet: `${api}stats/fleet/week/`,
    _monthlyFleet: `${api}stats/fleet/month/`,
    _single_trip: `${api}trips/`,

    _campaigns_new: `${api}campaigns/new/`,
    _campaigns_current: `${api}campaigns/current/`,
    _campaigns_past: `${api}campaigns/finished/`,
    _single_campaign: `${api}campaigns/`,

    _notifications: `${api}notifications/`,
    _car_list: `${api}df/driver-car/`,
    _cost_list: `${api}df/driver-costs/`,
    _claim_list: `${api}df/driver-claims/`,
    _driver_documents: `${api}df/driver-documents/`,
  },

  // Valid token for dashboard
  isValidToken: authToken,

  // Headers with method and token for api
  headerAuth: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  // Headers without method and token for POST , PUT , DELETE request api
  headerAxios: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + authToken,
  },

  headerDelete: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  },

  headerWithMedia: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + authToken,
    // "Accept-Language": window.localStorage.i18nextLng,
  },

  // Headers without method and token for GET request api
  headerNotAuth: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Accept-Language": window.localStorage.i18nextLng,
  },
};
export default Config;

// i18n.tsx

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./Language/English/en";
import pl from "./Language/Polish/pl";

const resources = {
  pl: pl,
  en: en,
};

i18n.use(LanguageDetector).init({
  resources,
  // lng: "pl", // default language
  fallbackLng: ["en", "pl"],
  preload: ["en", "pl"],
  whitelist: ["en", "pl"],
  debug: true,
  initImmediate: false,
  saveMissing: true,
  ns: ["translation"], // this is a directory
  defaultNS: "translation",
  interpolation: {
    escapeValue: false,
    formatSeparator: ".",
  },
  react: {
    wait: true,
  },
});

export default i18n;

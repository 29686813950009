import { lazy } from "react";
import SLUGS from "../resources/slugs/slug";

/*
==========================================
 Public Router Path
==========================================
*/

const Home = lazy(() => {
  return import("../Screen/Auth/Home");
});

const Login = lazy(() => {
  return import("../Screen/Auth/Login");
});

const Register = lazy(() => {
  return import("../Screen/Auth/Registration");
});

const ForgetPassword = lazy(() => {
  return import("../Screen/Auth/ForgetPasswrord");
});

/*
==========================================
 Private Router Path
==========================================
*/

const HomeDashboard = lazy(() => {
  return import("../Screen/Dashboard/Home");
});
const Trip = lazy(() => {
  return import("../Screen/Dashboard/Trip");
});

const DocumentUpload = lazy(() => {
  return import("../components/layout/MainLayout/Upload/DocumentUpload");
});

const AddCost = lazy(() => {
  return import("../components/layout/MainLayout/Costs");
});

const Report = lazy(() => {
  return import("../components/layout/MainLayout/Report");
});

/*
==========================================
 Stats Router Path
==========================================
*/

const Stats = lazy(() => {
  return import("../Screen/Stats");
});
const SingleStats = lazy(() => {
  return import("../Screen/Stats/SingleStats/SingleStats");
});

/*
==========================================
Campaigns Routes Path
==========================================
*/

const Campaigns = lazy(() => {
  return import("../Screen/Campaigns");
});

const SingleCampaigns = lazy(() => {
  return import("../Screen/Campaigns/Single");
});

/*
==========================================
Notifications Routes Path
==========================================
*/

const Notifications = lazy(() => {
  return import("../Screen/Notification");
});

/*
==========================================
Profi;e Routes Path
==========================================
*/

const Profile = lazy(() => {
  return import("../Screen/Profile");
});
const Car = lazy(() => {
  return import("../Screen/Profile/Car");
});

const Fleet = lazy(() => {
  return import("../Screen/Profile/Fleet");
});
const Cost = lazy(() => {
  return import("../Screen/Profile/Cost");
});

const Document = lazy(() => {
  return import("../Screen/Profile/Document");
});

const Claims = lazy(() => {
  return import("../Screen/Profile/Claims");
});

const Fuel = lazy(() => {
  return import("../Screen/Profile/Fuel");
});
const routesPath = {
  // Public Router Path without Authentication
  Public: [
    {
      path: SLUGS._homePath,
      component: <Home />,
    },
    {
      path: SLUGS._loginPath,
      component: <Login />,
    },

    {
      path: SLUGS._registerPath,
      component: <Register />,
    },
    {
      path: SLUGS._forgetPasswordPath,
      component: <ForgetPassword />,
    },
  ],

  Private: [
    {
      path: SLUGS._homePath,
      component: <HomeDashboard />,
    },
    {
      path: SLUGS._upload_documentPath,
      component: <DocumentUpload />,
    },
    {
      path: SLUGS._add_report_claimPath,
      component: <Report />,
    },
    {
      path: SLUGS._add_costsPath,
      component: <AddCost />,
    },
    {
      path: SLUGS._single_tripPath,
      component: <Trip />,
    },
    {
      path: SLUGS._statsPath,
      component: <Stats />,
    },
    {
      path: SLUGS._stats_single_dayPath,
      component: <SingleStats />,
    },
    {
      path: SLUGS._campaignsPath,
      component: <Campaigns />,
    },
    {
      path: SLUGS._single_campaignPath,
      component: <SingleCampaigns />,
    },
    {
      path: SLUGS._notificationsPath,
      component: <Notifications />,
    },
    {
      path: SLUGS._car_details,
      component: <Car />,
    },
    {
      path: SLUGS._fleet_details,
      component: <Fleet />,
    },
    {
      path: SLUGS._my_costs,
      component: <Cost />,
    },
    {
      path: SLUGS._my_documents,
      component: <Document />,
    },
    {
      path: SLUGS._my_claims,
      component: <Claims />,
    },
    {
      path: SLUGS._my_fuel_cards,
      component: <Fuel />,
    },
    {
      path: SLUGS._profilePath,
      component: <Profile />,
    },
  ],
};

export default routesPath;

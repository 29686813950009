import Login from "../../asset/Logo/Logo.png";
import Home from "../../asset/Logo/Home.png";

import Trip from "../../asset/Icon/Trip.png";
import Stats from "../../asset/Icon/Stats.png";
import Campaign from "../../asset/Icon/Campaign.png";
import Notification from "../../asset/Icon/Notification.png";
import Profile from "../../asset/Icon/Profile.png";
import NoData from "../../asset/NoData/NoData.png";
const Theme = {
  NoData: NoData,
  Public: {
    Login: Login,
    Home: Home,
  },
  Icon: {
    Trip: Trip,
    Stats: Stats,
    Campaign: Campaign,
    Notification: Notification,
    Profile: Profile,
  },
};

export default Theme;

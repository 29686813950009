import LoadingScreen from "../../asset/Logo/Loading.gif";
function Loading() {
  return (
    <div className="flex h-screen  bg-white justify-center  py-10   ">
      <div className="dot">
        <img src={LoadingScreen} alt="Loading" className="w-2/3 mx-auto" />
      </div>
    </div>
  );
}

export default Loading;
